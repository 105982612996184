/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  height: 100%;
  font-family: "Roboto", monospace !important;
}

body {
  margin: 0;
}

/* Font per tutte le parti dell'app, scommentare quello che si vuole usare e ricommentare quello sostituito */

.mat-typography {
  /* font-family: 'Anonymous Pro', monospace !important; */
  font-family: "Roboto", monospace !important;
}

.mat-button {
  /* font-family: 'Anonymous Pro', monospace !important; */
  font-family: "Roboto", monospace !important;
}

.mat-table {
  /* font-family: 'Anonymous Pro', monospace !important; */
  font-family: "Roboto", monospace !important;
  box-shadow: none;
}

.mat-tab-group {
  /* font-family: 'Anonymous Pro', monospace !important; */
  font-family: "Roboto", monospace !important;
  /* font-weight: bold !important; */
}

.mat-tab-label {
  /* font-family: 'Anonymous Pro', monospace !important; */
  font-family: "Roboto", monospace !important;
  font-size: large;
  font-weight: bold !important;
  opacity: inherit !important;
  width: -webkit-fill-available;
  height: 40px !important;
}

::ng-deep .mat-menu-panel {
  background: white !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.24) !important;
}

.mat-body {
  /* font-family: 'Anonymous Pro', monospace !important; */
  font-family: "Roboto", monospace !important;
}

.mat-icon-button {
  /* font-family: 'Anonymous Pro', monospace !important; */
  font-family: "Roboto", monospace !important;
}

.mat-header-cell {
  /* font-family: 'Anonymous Pro', monospace !important; */
  font-family: "Roboto", monospace !important;
}

/* Tabelle */
table {
  width: 100%;
}

tr {
  cursor: pointer;
}

/* LayoverAdmin */

/* Quando si passa il mouse su un tab si colora */
.mat-tab-label:hover {
  background-color: white !important;
}

/* Quando si seleziona un tab si colora */
.mat-tab-label-active {
  background-color: white !important;
}

/* Per eliminare divisorio tra i tab e il corpo dei tab*/
.mat-tab-header {
  height: 40px;
  border: 0;
}

/* Per sistemare la riga tra tab e bottone */
.mat-ink-bar {
  height: 0px !important;
}

/* Colorazioni dei vari bottoni sotto i tab */
.button-tab {
  height: 30px !important;
  line-height: 25px !important;
  width: 100% !important;
  border-radius: 0 !important;
  color: white;
}

.button-hr-0 {
  height: 30px;
  width: 40% !important;
  margin-left: 40%;
  background-color: #122b4f;
}

.button-hr-1 {
  height: 30px;
  width: 40% !important;
  margin-left: 80% !important;
  background-color: #122b4f;
}

.hr {
  width: 60%;
  margin: 0 0 0 20% !important;
  border: 0.5px solid white;
  background-color: #001634;
}

/* Parte reletiva agli utenti */
.intestazione-utenti {
  background-color: #122b4f !important;
}

.intestazione_utenti {
  height: 30px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 15px;
  margin-top: 30px;
  background-color: #122b4f !important;
  color: white;
}

.hr-utenti {
  width: 60%;
  border: 1px solid #001634;
  background-color: #001634;
  margin: 10px 0 10px 20% !important;
}

.bottone_utenti {
  height: 30px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 5px;
  margin-top: -8px;
  color: white;
}

.th-utenti.mat-header-cell {
  color: white;
}

/* Bordo expansion panel modali */
.mat-accordion > .mat-expansion-panel {
  border: 2px solid gray;
  margin-bottom: 10px;
}

/* Colore da usare sullo sfondo di una riga selezionata */
.selezionata {
  background-color: #4d7999 !important;
}

/* Colore da usare nel font di una riga selezionata */
.selezionata > td {
  color: white !important;
}

/* Colore da usare sullo sfondo di una riga accettata */
.accettata {
  background-color: lightgreen !important;
}

/* Forms */
mat-form-field {
  margin: 5px 0 5px 0;
}

.layover-form {
  width: 100%;
  max-height: calc(100vh - 50px);
  overflow: auto;
}

/* Spostamento elemaneti a sinistra */
.float-left {
  float: left;
}

/* Spostamento elemaneti a destra */
.float-right {
  float: right;
}

.h2 {
  font-size: 30px !important;
  margin: 0 !important;
}

tr.mat-header-row {
  cursor: default;
}

tr#sottoTabella.mat-row :hover .mat-cell {
  background-color: transparent !important;
  color: black !important;
}

.ngx-mat-tel-input-mat-menu-panel {
  min-height: 300px !important;
  max-height: 300px !important;
}

.cdk-overlay-pane {
  max-width: none !important;
}

.mat-dialog-container-dialog-message .mat-dialog-container {
  padding: 0px !important;
}

.mat-dialog-container-dialog-message .mat-dialog-container .mat-dialog-content {
  padding: 0px !important;
  margin: 0px !important;
}

.mat-dialog-container-dialog-message .mat-dialog-container .mat-dialog-actions {
  padding: 0px !important;
  margin: 0px !important;
}

.no-padding mat-dialog-container {
  padding: 0px 0px;
}

.mat-menu-content {
  width: 100%;
  height: 100%;
}

.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline {
  color: rgba(77, 121, 153, 1);
}

.mat-form-field-appearance-outline:not(.mat-form-field-disabled)
  .mat-form-field-flex:hover
  .mat-form-field-outline {
  color: rgba(77, 121, 153, 1);
}

.mat-form-field-appearance-outline .mat-form-field-outline {
  stroke: rgba(0, 0, 0, 0.42);
}

.mat-form-field-appearance-outline.mat-focused .mat-form-field-label {
  color: rgba(77, 121, 153, 1);
}

.mat-form-field-appearance-outline .mat-form-field-label {
  color: rgba(0, 0, 0, 0.6);
}

.mat-calendar-body-cell-content.mat-focused {
  background-color: #4d7999 !important;
  color: white !important;
}

.mat-calendar-body-cell-content:hover {
  background-color: #4d7999 !important;
  color: white !important;
}

.mat-calendar-body-selected .mat-calendar-body-cell-content {
  background-color: #fedd40 !important;
  color: black !important;
}

.mat-calendar-body-selected {
  background-color: #4d7999 !important;
  color: white !important;
}

.mat-calendar-body-today {
  background-color: #4d7999 !important;
  color: white !important;
}

.mdc-button__label {
  color: #4d7999 !important;
}

.mdc-snackbar--open {
  background-color: rgb(51, 51, 51);
  color: white;
  border-radius: 8px;
}

.mat-mdc-button {
  color: rgb(0, 150, 206) !important;
}

@keyframes mat-spinner {
  to {
    transform: rotate(360deg);
  }
}

.mat-spinner-button::after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  border-top-color: #4d7999;
  animation: mat-spinner 0.8s linear infinite;
}

.mat-progress-spinner circle,
.mat-spinner circle {
  stroke: #4d7999;
}

.hide-btn-text {
  color: transparent !important;
}

.mat-glow {
  animation: glow 1s infinite alternate;
}

@keyframes glow {
  from {
    box-shadow: 0 0 3px -3px var(--glow-color);
  }
  to {
    box-shadow: 0 0 3px 3px var(--glow-color);
  }
}

@keyframes fade {
  from {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  to {
    opacity: 1;
  }
}

@-webkit-keyframes fade {
  from {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  to {
    opacity: 1;
  }
}

.blink {
  animation: fade 1000ms infinite;
  -webkit-animation: fade 1000ms infinite;
}

.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.blink-row-updated {
  color: #4d5382;
  animation: blinker 1s linear 5;
}

@keyframes blinker {
  from {
    background-color: rgba(47, 159, 167, 0.7);
  }
  to {
    background-color: none;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.my-tooltip {
  white-space: pre-line !important;
}

.text-canceled {
  text-decoration-line: line-through;
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}

.mat-mdc-menu-content {
  background-color: white !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.24) !important;
  justify-content: center;
}

.horizontal-menu .mat-menu-content {
  display: flex;
  flex-direction: row; /* Align items horizontally */
  background: white; /* Non-transparent background */
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2); /* Drop shadow for better visibility */
}

.fixed-height-detail {
  margin: 0 !important;
  padding: 0 !important;
  max-height: calc(100% - 50px) !important;
}

.fixed-height-editor {
  max-height: calc(100% - 50px) !important;
}

.fixed-height-rooming-list-editor {
  height: calc(100% - 230px) !important;
  max-height: calc(100% - 230px) !important;
}

.fixed-height-rooming-list-editor-mobile {
  height: calc(100% - 325px) !important;
  max-height: calc(100% - 325px) !important;
}

.mat-dialog-override mat-dialog-container > :first-child {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.mat-dialog-override mat-dialog-container mat-dialog-content,
div[mat-dialog-content] {
  flex-grow: 1;
  max-height: unset;
}

/* mat-dialog-container {
  padding-top: 0 !important;
} */

mat-dialog-container .dialog-title {
  position: sticky;
  height: 50px;
  background-color: white;
  top: 0px;
  z-index: 10;
  padding-top: 20px;
}

.buy-color {
  color: rgba(138, 41, 24, 1);
}

.sell-color {
  color: rgba(24, 138, 103, 1);
}

.profit-color {
  color: #186e8a;
}

.selected-link {
  color: #e1c556;
}

.mat-table-sticky {
  top: 4.25rem !important;
  z-index: 9 !important;
}


.mat-mdc-autocomplete-panel {
  background-color: #fff;
}

.mat-mdc-option:hover:not(.mdc-list-item--disabled) {
  background-color: rgba(0, 0, 0, 0.04);
}
